import { Component, Input, OnInit } from "@angular/core";
import { SurveyResultsModel } from "@shared/survey/models/survey-results.model";
import { getSurveyResultRequest } from "@survey/store/survey.actions";
import {
  Team350Store,
  TrackingHelpers,
} from "@shared/store/action-trackers/services/team-350.store";
import { Subject, takeUntil } from "rxjs";
import { isNil } from "lodash";

@Component({
  selector: "app-survey-results",
  templateUrl: "./survey-results.component.html",
  styleUrls: ["./survey-results.component.scss"],
})
export class SurveyResultsComponent implements OnInit {
  // TODO this will need to be changed when we add different question types

  @Input() question: string;
  @Input() surveyId: string;

  loading: boolean = true;
  destroyed$ = new Subject<void>();
  results: SurveyResultsModel[];
  getSurveyResultsTracker: TrackingHelpers;
  showErrorText: boolean = false;

  resultColours: string[] = [
    "#D35858",
    "#137428",
    "#93BFD5",
    "#FFB253",
    "#EC7CF2",
    "#9b1342",
    "#17f5a3",
    "#825ab4",
    "#697241",
    "#1c8b9d",
  ];

  constructor(private store: Team350Store) {}

  ngOnInit() {
    this.getSurveyResultsTracker = this.store.trackDispatch(
      getSurveyResultRequest({ surveyId: this.surveyId }),
    );

    this.getSurveyResultsTracker
      .onComplete()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          if (!isNil(response)) {
            this.loading = false;
            this.results = response.data as SurveyResultsModel[];
          }
        },
        error: (error) => {
          this.loading = false;
          this.showErrorText = true;
        },
      });
  }
}
