import { Component, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import {
  Team350Store,
  TrackingHelpers,
} from "@shared/store/action-trackers/services/team-350.store";
import { ActivatedRoute, Router } from "@angular/router";
import { config } from "@shared/config";
import { isNil } from "lodash";
import { getSurveyForPublicPage } from "@survey/store/survey.actions";
import { PlatformDetailsService } from "@shared/services/platform-details.service";
import { Survey } from "@models";

@Component({
  selector: "app-public-survey-page",
  templateUrl: "./public-survey-page.component.html",
  styleUrls: ["./public-survey-page.component.scss"],
})
export class PublicSurveyPageComponent implements OnInit {
  platformName: string;
  platformLogoFileName: string;
  platformTheme: string;
  surveyTrackingHelper: TrackingHelpers;
  survey: Survey;
  protected readonly config = config;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private store: Team350Store,
    private router: Router,
    private platformDetailsService: PlatformDetailsService,
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.platformName = params["platform"];
        if (!isNil(params["platform"])) {
          const platformDetails =
            this.platformDetailsService.setPlatformDetails(this.platformName);
          this.platformTheme = platformDetails.platformTheme;
          this.platformLogoFileName = platformDetails.platformLogoFileName;
          this.surveyTrackingHelper = this.store.trackDispatch(
            getSurveyForPublicPage({ platformName: this.platformName }),
          );
        }
      });

    this.surveyTrackingHelper
      .onComplete()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (!isNil(result)) {
          this.survey = result.data as Survey;
        }
      });
  }

  ngOnInit() {}

  landingRedirect() {
    this.router.navigate(["/landing"]);
  }
}
