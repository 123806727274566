<div class="take-your-place-container">
  <div class="take-your-place-content">
    <div class="logo-header">
      <img
        (click)="landingRedirect()"
        alt="logo"
        class="logo"
        src="/assets/images/platform-logos/board-house-logo-dark.svg"
      />
    </div>

    <div class="form-container">
      <p class="heading">Apply to join Boardhouse</p>
      <app-sign-up-form-stepper
        [pageNumber]="pageNumber"
        [platformName]="frontendPlatformName"
      ></app-sign-up-form-stepper>

      <form
        (keydown.enter)="$event.preventDefault()"
        [formGroup]="signupForm"
        class="form"
      >
        <div *ngIf="pageNumber === 1" class="page-1-container">
          <p class="form-heading">Personal information:</p>
          <div class="input-label">
            <label> Name (required)*</label>
          </div>
          <div class="name-inputs">
            <div class="input-container name-input">
              <input
                class="thin-secondary-input"
                formControlName="firstName"
                placeholder="First (required)"
                type="text"
              />
              <p class="error-text">
                {{
                  getErrorMessage(
                    signupForm.controls["firstName"],
                    "First name"
                  )
                }}
              </p>
            </div>

            <div class="input-container name-input">
              <input
                class="thin-secondary-input"
                formControlName="middleName"
                placeholder="Middle (strongly preferred)"
                type="text"
              />
            </div>

            <div class="input-container name-input">
              <input
                class="thin-secondary-input"
                formControlName="lastName"
                placeholder="Last (required)"
                type="text"
              />
              <p class="error-text">
                {{
                  getErrorMessage(signupForm.controls["lastName"], "Last name")
                }}
              </p>
            </div>
          </div>

          <div class="input-container">
            <div class="input-label">
              <label>
                Email (required)*
                <span class="descriptive-text"
                  >Please use your personal email - this will be the email you
                  use to log in</span
                >
              </label>
            </div>

            <input
              class="thin-secondary-input"
              formControlName="email"
              placeholder="Email"
              type="text"
            />
            <p class="error-text">
              {{ getErrorMessage(signupForm.controls["email"], "Email") }}
            </p>
          </div>
          <div class="input-container">
            <div class="input-label">
              <label> Confirm your email (required)* </label>
            </div>
            <input
              class="thin-secondary-input"
              formControlName="confirmEmail"
              placeholder="Confirm your email"
              type="text"
            />
            <p class="error-text">
              {{
                getErrorMessage(
                  signupForm.controls["confirmEmail"],
                  "Confirm Email"
                )
              }}
            </p>
          </div>

          <div class="input-container">
            <div class="input-label">
              <label>Alternative contact email (where possible) </label>
            </div>

            <input
              class="thin-secondary-input"
              formControlName="recoveryEmail"
              placeholder="Alternative email"
              type="text"
            />
            <p class="error-text">
              {{
                getErrorMessage(
                  signupForm.controls["recoveryEmail"],
                  "Recovery email"
                )
              }}
            </p>
          </div>

          <div class="input-container">
            <div class="input-label">
              <label>Mobile phone (required)* </label>
            </div>

            <div class="flex flex-row">
              <app-dropdown-component
                (touchedEvent)="
                  signupForm.controls['cellphoneNumber'].markAsTouched()
                "
                [options]="formattedCountryCodes"
                formControlName="countryCode"
                placeholder="select"
                type="prefix"
              ></app-dropdown-component>
              <input
                (blur)="signupForm.controls['countryCode'].markAsTouched()"
                class="input-with-prefix"
                digitOnly
                formControlName="cellphoneNumber"
                maxlength="15"
                placeholder="Mobile number"
                type="text"
              />
            </div>
            <p class="error-text">
              {{
                getErrorMessage(
                  signupForm.controls["cellphoneNumber"],
                  "Mobile number"
                )
              }}
            </p>
            <p class="error-text">
              {{
                getErrorMessage(
                  signupForm.controls["countryCode"],
                  "Country code"
                )
              }}
            </p>
          </div>
          <div
            *ngFor="let locationForm of locationsFormGroup; index as i"
            class="input-container"
          >
            <div class="input-label">
              <label
                >Location (required)*
                <!--                  <span class="descriptive-text"-->
                <!--                    >Please amend if you are not in the UK</span-->
                <!--                  >-->
              </label>
            </div>
            <div [formGroup]="locationForm">
              <div class="location-container">
                <app-dropdown-component
                  [options]="countries"
                  class="flex-grow"
                  formControlName="country"
                  placeholder="Select country"
                  type="full"
                >
                </app-dropdown-component>

                <app-dropdown-component
                  *ngIf="isCountrySelected"
                  [options]="countryCities"
                  class="flex-grow"
                  formControlName="city"
                  placeholder="Select city"
                  type="full"
                >
                </app-dropdown-component>

                <svg
                  (click)="removeLocation(i)"
                  *ngIf="i !== 0"
                  aria-hidden="true"
                  class="delete-icon-btn"
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </div>

              <p class="error-text">
                {{
                  getErrorMessage(locationForm.controls["location"], "Location")
                }}
              </p>
            </div>
          </div>

          <div *ngIf="locationsFormGroup.length < 2">
            <span (click)="addLocation()" class="hyperlink cursor-pointer"
              >Add a second location +</span
            >
          </div>

          <div class="input-container sm:mt-6">
            <div class="input-label">
              <label>
                Select languages where you are fluent or a native speaker
                (required)*
              </label>
            </div>
            <app-dropdown-component
              [isSingle]="false"
              [options]="languages"
              formControlName="languages"
              type="full"
            ></app-dropdown-component>
          </div>
          <p class="form-heading">Log in information</p>
          <div class="flex flex-col items-center w-full">
            <div class="input-container">
              <div class="input-label">
                <label>Create your password (required)*</label>
              </div>
              <input
                class="thin-secondary-input"
                formControlName="password"
                placeholder="Password"
                type="password"
              />
              <p class="error-text">
                {{
                  getErrorMessage(signupForm.controls["password"], "Password")
                }}
              </p>
            </div>

            <div class="input-container">
              <div class="input-label">
                <label>Confirm password (required)*</label>
              </div>

              <input
                class="thin-secondary-input"
                formControlName="confirmPassword"
                placeholder="Password"
                type="password"
              />
              <p class="error-text">
                {{
                  getErrorMessage(
                    signupForm.controls["confirmPassword"],
                    "Confirm Password"
                  )
                }}
              </p>
            </div>
            <button
              (click)="gotoNextPage()"
              [ngClass]="personalDetailsValid ? '' : 'disabled'"
              class="outlined-button next-button"
              id="take_your_place_step_1_next"
            >
              Next
            </button>
          </div>
        </div>

        <div *ngIf="pageNumber === 2" class="page-2-container">
          <p class="form-heading">Role and diversity information</p>
          <div>
            <div class="input-container">
              <div class="input-label">
                <label
                  >Primary role (required)*
                  <span class="descriptive-text">
                    We recommend you give your FTSE 350 role as a primary role
                  </span></label
                >
              </div>

              <input
                class="thin-secondary-input"
                formControlName="role"
                name="role"
                placeholder="Enter role name"
                type="text"
              />

              <p class="text-xs mt-1 ml-1 text-red-500">
                {{ getErrorMessage(signupForm.controls["role"], "Role") }}
              </p>
            </div>

            <div class="form-field-padding">
              <div class="input-label">
                <label>Is this a FTSE 350 company? (required)*</label><br />
              </div>
              <div class="bool-options">
                <input
                  [value]="true"
                  class="options radio-checkbox"
                  formControlName="isFTSECompany"
                  name="isFTSECompany"
                  type="radio"
                />
                <label class="bool-option">Yes</label><br />
                <input
                  [value]="false"
                  class="options radio-checkbox"
                  formControlName="isFTSECompany"
                  name="isFTSECompany"
                  type="radio"
                />
                <label class="bool-option">No</label><br />
              </div>
              <p class="text-xs mt-1 ml-1 text-red-500">
                {{
                  getErrorMessage(
                    signupForm.controls["isFTSECompany"],
                    "FTSE Company option"
                  )
                }}
              </p>
            </div>

            <div
              *ngIf="
                signupForm.get('isFTSECompany')?.dirty &&
                !signupForm.get('isFTSECompany')?.value
              "
              class="input-container"
            >
              <div class="input-label">
                <label>Company name (required)*</label>
              </div>
              <input
                class="thin-secondary-input"
                formControlName="nonFTSECompanyName"
                name="nonFTSECompanyName"
                placeholder="Enter company name"
                type="text"
              />
            </div>

            <div
              *ngIf="
                signupForm.get('isFTSECompany')?.dirty &&
                signupForm.get('isFTSECompany')?.value
              "
              class="input-container"
            >
              <div class="input-label">
                <label>Company name (required)*</label>
              </div>
              <app-dropdown-component
                [options]="sortedCompanies"
                [placeholder]="'Select your company'"
                formControlName="company"
                type="full"
              ></app-dropdown-component>
              <p class="text-xs mt-1 ml-1 text-red-500">
                {{ getErrorMessage(signupForm.controls["company"], "Company") }}
              </p>
            </div>

            <div class="input-container">
              <div class="input-label">
                <label
                  >Role status (required)*
                  <span class="descriptive-text">
                    Please amend if this is not your current role</span
                  >
                </label>
              </div>
              <app-dropdown-component
                [options]="statusOption"
                formControlName="status"
                type="full"
              ></app-dropdown-component>
            </div>

            <div class="input-container">
              <div class="input-label">
                <label>What is your seniority in the company?* </label>
              </div>
              <app-dropdown-component
                [options]="seniorityOptions"
                formControlName="seniority"
                type="full"
              ></app-dropdown-component>
              <input
                *ngIf="isOtherSeniority"
                class="mt-3 thin-secondary-input"
                formControlName="otherSeniority"
                placeholder="Enter seniority name"
                type="text"
              />
            </div>
          </div>

          <!-- Hide until we have correctly handled the ability to add a secondary role -->
          <span *ngIf="!secondaryRoleAddition && false">
            (click)="showSecondaryRoleField()" class="text-md underline
            cursor-pointer" > Add a secondary role +
          </span>

          <div *ngIf="secondaryRoleAddition" class="input-container">
            <p class="text-h3 form-field-padding">Secondary Role</p>
            <div class="input-container">
              <label class="form-field-padding"
                >Role (required)*
                <span class="descriptive-text">
                  We suggest you give your FTSE 350 role as a primary role
                </span></label
              >
              <app-dropdown-component
                [options]="sortedCompanyRoles"
                [placeholder]="'Select your role'"
                formControlName="secondaryRole"
                type="full"
              ></app-dropdown-component>
              <p class="text-xs mt-1 ml-1 text-red-500">
                {{
                  getErrorMessage(
                    signupForm.controls["secondaryRole"],
                    "Secondary Role"
                  )
                }}
              </p>
            </div>

            <div class="form-field-padding">
              <div class="input-label">
                <label>Is this a FTSE 350 company?</label><br />
              </div>
              <div class="bool-options">
                <input
                  [value]="true"
                  class="options radio-checkbox"
                  formControlName="isSecondaryFTSECompany"
                  name="isSecondaryFTSECompany"
                  type="radio"
                />
                <label class="bool-option">Yes</label><br />
                <input
                  [value]="false"
                  class="options radio-checkbox"
                  formControlName="isSecondaryFTSECompany"
                  name="isSecondaryFTSECompany"
                  type="radio"
                />
                <label class="bool-option">No</label><br />
              </div>

              <p class="text-xs mt-1 ml-1 text-red-500">
                {{
                  getErrorMessage(
                    signupForm.controls["isSecondaryFTSECompany"],
                    "FTSE Company option"
                  )
                }}
              </p>
            </div>

            <div
              *ngIf="
                signupForm.get('isSecondaryFTSECompany')?.dirty &&
                !signupForm.get('isSecondaryFTSECompany')?.value
              "
              class="input-container"
            >
              <label class="form-field-padding">Company name (required)*</label>
              <input
                class="thin-secondary-input"
                formControlName="secondaryNonFTSECompanyName"
                name="secondaryNonFTSECompanyName"
                placeholder="Enter company name"
                type="text"
              />
            </div>

            <div
              *ngIf="
                signupForm.get('isSecondaryFTSECompany')?.dirty &&
                signupForm.get('isSecondaryFTSECompany')?.value
              "
              class="input-container"
            >
              <label class="form-field-padding">Company name (required)*</label>
              <app-dropdown-component
                [options]="sortedCompanies"
                [placeholder]="'Select your company'"
                formControlName="secondaryCompany"
                type="full"
              ></app-dropdown-component>
              <p class="text-xs mt-1 ml-1 text-red-500">
                {{
                  getErrorMessage(
                    signupForm.controls["secondaryCompany"],
                    "Company"
                  )
                }}
              </p>
            </div>

            <div class="input-container">
              <label class="form-field-padding"
                >Role status (required)*
                <span class="descriptive-text">
                  We recommend you give your FTSE 350 role as a primary role
                </span>
              </label>
              <app-dropdown-component
                [options]="statusOption"
                formControlName="secondaryStatus"
                type="full"
              ></app-dropdown-component>
            </div>
          </div>

          <div class="input-label">
            <label>Sector experience (required)*</label>
          </div>
          <div class="input-container">
            <app-dropdown-component
              [isSingle]="false"
              [options]="sectorExperience"
              formControlName="sectorExperience"
              type="full"
            ></app-dropdown-component>
          </div>

          <div class="input-label">
            <label>Domain expertise (required)*</label>
          </div>
          <div class="input-container">
            <app-dropdown-component
              [isSingle]="false"
              [options]="expertise"
              formControlName="expertise"
              type="full"
            ></app-dropdown-component>
          </div>

          <div class="input-label">
            <label>Interests (required)*</label>
          </div>
          <div class="input-container">
            <app-dropdown-component
              [isSingle]="false"
              [options]="interests"
              formControlName="interests"
              type="full"
            ></app-dropdown-component>
          </div>

          <div>
            <div class="input-label">
              <label>Are you still a serving executive? (required)*</label
              ><br />
            </div>
            <div class="bool-options">
              <input
                [value]="true"
                class="options radio-checkbox"
                formControlName="executive"
                name="executive"
                type="radio"
              />
              <label class="bool-option">Yes</label><br />
              <input
                [value]="false"
                class="options radio-checkbox"
                formControlName="executive"
                name="executive"
                type="radio"
              />
              <label class="bool-option">No</label><br />
            </div>
          </div>

          <div>
            <div class="input-label">
              <label>Gender (required)*</label><br />
            </div>
            <div *ngFor="let gender of genderOptions">
              <input
                [value]="gender"
                class="radio-checkbox"
                formControlName="gender"
                name="gender"
                type="radio"
              />
              <label class="ml-2">{{ gender }}</label
              ><br />
            </div>

            <p class="error-text">
              {{ getErrorMessage(signupForm.controls["gender"], "Gender") }}
            </p>
          </div>

          <div>
            <div class="input-label">
              <label>Are you a qualified accountant? (required)*</label><br />
            </div>
            <div class="bool-options">
              <input
                [value]="true"
                class="options radio-checkbox"
                formControlName="accountant"
                name="accountant"
                type="radio"
              />
              <label class="bool-option">Yes</label><br />
              <input
                [value]="false"
                class="options radio-checkbox"
                formControlName="accountant"
                name="accountant"
                type="radio"
              />
              <label class="bool-option">No</label><br />
            </div>
          </div>

          <div class="mb-3">
            <div class="input-label">
              <label>Do you identify as the following?</label><br />
            </div>
            <div
              *ngFor="let option of diversity; let i = index"
              formArrayName="diversity"
            >
              <input
                [formControlName]="i"
                [value]="option"
                class="radio-checkbox"
                type="checkbox"
              />
              <label class="ml-2">{{ option }}</label
              ><br />
            </div>
            <p class="error-text">
              {{
                getErrorMessage(signupForm.controls["diversity"], "diversity")
              }}
            </p>
            <input
              [disabled]="diversityDisclosed"
              [formControlName]="'diversityUndisclosed'"
              [value]="'Prefer not to say'"
              class="radio-checkbox"
              type="checkbox"
            />

            <label class="ml-2">Prefer not to say</label>
          </div>

          <p *ngIf="valueAddedServices.length > 0" class="text-md mb-5">
            Additionally:
          </p>

          <p *ngIf="valueAddedServices.length > 0" class="text-md mb-5">
            We can offer the following board member services, please tick if you
            are interested:
          </p>
          <div class="mb-6">
            <div
              *ngFor="let option of valueAddedServices; let i = index"
              class="flex items-center pb-3"
              formArrayName="valueAddedServices"
            >
              <input
                [formControlName]="i"
                [value]="option.title"
                class="options radio-checkbox"
                type="checkbox"
              />
              <label class="ml-2">{{ option.title }}</label
              ><br />
            </div>
          </div>

          <div class="pl-1 mb-10">
            <input
              [formControlName]="'acceptTerms'"
              class="tc-checkbox"
              type="checkbox"
            />
            <label class="ml-2"
              >I agree to the 350 Club's
              <a [href]="'/info/terms-and-conditions?platform=' + platformName"
                >terms and conditions</a
              >
              and
              <a
                [href]="'/info/privacy-policy?platform=' + platformName"
                target="_blank"
                >privacy policy</a
              >
              (required)*</label
            >
          </div>

          <div class="flex justify-center">
            <button
              (click)="gotoPreviousPage()"
              class="dark-outlined-button back-button"
              id="take_your_place_step_2_back"
            >
              Back
            </button>
            <button
              (click)="submitForm()"
              [disabled]="!signupForm.valid"
              class="outlined-button"
              id="take_your_place_submit"
            >
              Submit application
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <app-general-alert-modal [action]="signUpAction" [onlyShowError]="true" />
</div>
