import { SystemPageNameInterface } from "@shared/interfaces/system-page-name.interface";
import { frontendRoutes } from "@shared/config/frontend-routes.config";
import { SystemPageEnum } from "@shared/enums/system-page.enum";

export const sidebar: SystemPageNameInterface[] = [
  {
    systemPageName: "home-page",
    route: frontendRoutes.home,
  },
  {
    systemPageName: "directory-page",
    route: frontendRoutes.directory,
  },
  {
    systemPageName: "numerable-page",
    route: frontendRoutes.numerable,
  },
  {
    systemPageName: "equity-research",
    route: frontendRoutes.equityResearch,
  },
  {
    systemPageName: "regulatory-news",
    route: frontendRoutes.regulatoryNews,
  },
  {
    systemPageName: "corporate-news",
    route: frontendRoutes.corporateNews,
  },

  {
    systemPageName: "groups-page",
    route: frontendRoutes.groups,
  },
  {
    systemPageName: "events-page",
    route: frontendRoutes.events,
  },
  {
    systemPageName: "vacancies-page",
    route: frontendRoutes.vacancies,
  },
  {
    systemPageName: SystemPageEnum.askAnAdvisor,
    route: "home/advisors",
  },
  {
    systemPageName: "courses-page",
    route: "/home/courses",
  },
  {
    systemPageName: "noticeboard-page",
    route: "home/noticeboard",
  },
  {
    systemPageName: "mentorship-page",
    route: frontendRoutes.mentorship,
    mentorRoute: frontendRoutes.mentorView,
    menteeRoute: frontendRoutes.menteeView,
  },
  {
    systemPageName: "survey-page",
    route: "home/survey",
  },
  {
    systemPageName: "subscription-page",
    route: "home/subscribe",
  },
  {
    systemPageName: "service-page",
    route: "home/services",
  },
  {
    systemPageName: SystemPageEnum.coaching,
    route: frontendRoutes.coaching
  }
];
