<app-spinner-and-text *ngIf="loading"></app-spinner-and-text>

<div *ngIf="!loading && !showErrorText" class="p-4">
  <span class="survey-heading">MEMBER'S VIEWS:</span>
  <p class="survey-title mx-2">{{ question }}</p>
  <div class="py-4">
    <div
      *ngFor="let result of results; let i = index"
      class="mx-2 flex justify-between"
    >
      <div class="question-value">
        <div
          [ngStyle]="{
            'background-color': resultColours[i % resultColours.length]
          }"
          class="coloured-circle"
        ></div>
        {{ result.questionValue }}
      </div>
      <div class="result-percentage">{{ result.percentageValue }}%</div>
    </div>
  </div>
  <span class="sub-text">Thank you for participating in our survey!</span>
</div>

<div *ngIf="showErrorText" class="no-results-text">
  No results can be displayed at this time
</div>
