<div class="survey-card">
  <survey *ngIf="!surveySubmitted" [model]="model"></survey>
  <form
    *ngIf="!surveySubmitted && !userLoggedIn"
    [formGroup]="surveyEmailForm"
    class="form mt-3 px-5"
  >
    <div class="form-controls-container">
      <div class="input-container">
        <div class="input-label text-left">
          <label> Email (required)*</label>
        </div>
        <input
          class="secondary-input"
          formControlName="email"
          placeholder="Your email address"
          type="text"
        />
        <span
          *ngIf="
            surveyEmailForm.get('email')?.dirty &&
            surveyEmailForm.get('email')?.errors
          "
          class="error-text"
        >
          Your email is invalid
        </span>
      </div>
    </div>
    <div class="flex justify-center w-full pb-6">
      <button
        (click)="submitResponse()"
        *ngIf="!surveySubmitted"
        [disabled]="!formValid"
        [ngClass]="!formValid ? 'disabled' : ''"
        class="dark-outlined-button"
      >
        Submit
      </button>
    </div>
  </form>
  <div class="flex justify-center w-full pb-6">
    <button
      (click)="submitResponse()"
      *ngIf="!surveySubmitted && userLoggedIn"
      [disabled]="!formValid"
      [ngClass]="!formValid ? 'disabled' : ''"
      class="dark-outlined-button"
    >
      Submit
    </button>
  </div>

  <app-survey-results
    *ngIf="surveySubmitted"
    [question]="survey.title"
    [surveyId]="survey.surveyId"
  ></app-survey-results>
  <div class="flex justify-center w-full py-6">
    <button
      (click)="navigateToHomePage()"
      *ngIf="surveySubmitted"
      class="dark-outlined-button"
    >
      Back to home page
    </button>
  </div>
</div>
