import { createAction, props } from "@ngrx/store";
import { SurveyRequest } from "../models/survey-request.model";
import { Survey } from "@models";
import { EditSurveyDto } from "@shared/api-client";
import { SurveyResponseModel } from "../models/survey-response.model";
import { PublicSurveyResponseModel } from "@survey/models/public-survey-response.model";
import { SurveyResultsModel } from "@shared/survey/models/survey-results.model";

export const saveSurvey = createAction(
  "[Survey] Save Survey Request",
  props<{ surveyRequest: SurveyRequest }>(),
);

export const saveSurveySuccess = createAction(
  "[Survey] Save Survey Request Success",
  props<{ survey: Survey }>(),
);

export const getSingleSurvey = createAction(
  "[Survey] Get Single Survey Request",
  props<{ surveyId: string }>(),
);

export const getSingleSurveySuccess = createAction(
  "[Survey] Get Single Survey Request Success",
  props<{ survey: Survey }>(),
);

export const editSurvey = createAction(
  "[Survey] Edit Survey Request",
  props<{ editSurveyDto: EditSurveyDto }>(),
);

export const editSurveySuccess = createAction(
  "[Survey] Edit Survey Request Success",
);

export const getSurveyForLandingPage = createAction(
  "[Survey] Get Survey For Landing Page",
);

export const getSurveyForLandingPageSuccess = createAction(
  "[Survey] Get Survey For Landing Page Success",
  props<{ survey: Survey }>(),
);

export const submitSurveyResponse = createAction(
  "[Survey] Submit Survey Response",
  props<{ surveyResponse: SurveyResponseModel }>(),
);

export const submitSurveyResponseSuccess = createAction(
  "[Survey] Submit Survey Response Success",
);

export const getSurveyForPublicPage = createAction(
  "[Survey] Get Survey For Public Page",
  props<{ platformName: string }>(),
);

export const getSurveyForPublicPageSuccess = createAction(
  "[Survey] Get Survey For Public Page Success",
  props<{ survey: Survey }>(),
);

export const submitResponseForPublicSurvey = createAction(
  "[Survey] Submit Response For Public Survey",
  props<{ surveyResponse: PublicSurveyResponseModel }>(),
);

export const submitResponseForPublicSurveySuccess = createAction(
  "[Survey] Submit Survey Response Success",
);

export const uploadSurveyScreenshot = createAction(
  "[Survey] Upload Survey Screenshot",
  props<{ surveyId: string; files: Blob[] }>(),
);

export const uploadSurveyScreenshotSuccess = createAction(
  "[Survey] Upload Survey Screenshot Success",
);

export const getSurveyResultRequest = createAction(
  "[Survey] Get Survey Result Request",
  props<{ surveyId: string }>(),
);

export const getSurveyResultRequestSuccess = createAction(
  "[Survey] Get Survey Result Request Success",
  props<{ surveyResults: SurveyResultsModel }>(),
);
