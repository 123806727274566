export enum SystemPageEnum {
  home = "home-page",
  directory = "directory-page",
  vacancies = "vacancies-page",
  groups = "groups-page",
  events = "events-page",
  numerable = "numerable-page",
  noticeboard = "noticeboard-page",
  askAnAdvisor = "ask-an-advisor-page",
  news = "news-page",
  equityResearch = "equity-research",
  corporateNews = "corporate-news",
  regulatoryNews = "regulatory-news",
  mentorship = "mentorship-page",
  coaching = "coaching-page",
}
