import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, of, switchMap } from "rxjs";
import {
  completeActionTracker,
  failActionTracker,
} from "@shared/store/action-trackers/ngrx/action-tracker.actions";
import {
  PublicSurveyClientApiService,
  SurveyClientApiService,
} from "@shared/api-client";
import {
  editSurvey,
  editSurveySuccess,
  getSingleSurvey,
  getSingleSurveySuccess,
  getSurveyForLandingPage,
  getSurveyForLandingPageSuccess,
  getSurveyForPublicPage,
  getSurveyForPublicPageSuccess,
  getSurveyResultRequest,
  getSurveyResultRequestSuccess,
  saveSurvey,
  saveSurveySuccess,
  submitResponseForPublicSurvey,
  submitResponseForPublicSurveySuccess,
  submitSurveyResponse,
  submitSurveyResponseSuccess,
  uploadSurveyScreenshot,
  uploadSurveyScreenshotSuccess,
} from "./survey.actions";

@Injectable()
export class SurveyEffects {
  saveSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveSurvey),
      switchMap((action) =>
        this.surveyClientApiService
          .surveyControllerCreateSurvey(action.surveyRequest)
          .pipe(
            switchMap((response) => {
              return [
                saveSurveySuccess(response.data),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) =>
              of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error?.message },
                }),
              ),
            ),
          ),
      ),
    ),
  );
  getSingleSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSingleSurvey),
      switchMap((action) =>
        this.surveyClientApiService
          .surveyControllerGetSingleSurvey(action.surveyId)
          .pipe(
            switchMap((response) => {
              return [
                getSingleSurveySuccess(response.data),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) =>
              of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error?.message },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  editSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editSurvey),
      switchMap((action) =>
        this.surveyClientApiService
          .surveyControllerEditSurvey(action.editSurveyDto)
          .pipe(
            switchMap((response) => {
              return [
                editSurveySuccess(),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) =>
              of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error?.message },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getSurveyForLandingPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSurveyForLandingPage),
      switchMap((action) =>
        this.surveyClientApiService
          .surveyControllerGetSurveyForLandingPage()
          .pipe(
            switchMap((response) => {
              return [
                getSurveyForLandingPageSuccess(response.data),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) =>
              of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error?.message },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  submitSurveyResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitSurveyResponse),
      switchMap((action) =>
        this.surveyClientApiService
          .surveyControllerSubmitSurveyResponse(action.surveyResponse)
          .pipe(
            switchMap((response) => {
              return [
                submitSurveyResponseSuccess(),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) => {
              return of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error?.message },
                }),
              );
            }),
          ),
      ),
    ),
  );

  getSurveyForPublicPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSurveyForPublicPage),
      switchMap((action) =>
        this.publicSurveyService
          .publicSurveyControllerGetLatestSurvey(action.platformName)
          .pipe(
            switchMap((response) => {
              return [
                getSurveyForPublicPageSuccess(response.data),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) =>
              of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error?.message },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  submitPublicSurveyResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitResponseForPublicSurvey),
      switchMap((action) =>
        this.publicSurveyService
          .publicSurveyControllerSubmitSurveyResponse(action.surveyResponse)
          .pipe(
            switchMap((response) => {
              return [
                submitResponseForPublicSurveySuccess(),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) =>
              of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error?.message },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  uploadSurveyScreenshot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadSurveyScreenshot),
      switchMap((action) =>
        this.surveyClientApiService
          .surveyControllerUploadSurveyScreenshot(action.surveyId, action.files)
          .pipe(
            switchMap((response) => {
              return [
                uploadSurveyScreenshotSuccess(),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) =>
              of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error?.message },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getSurveyResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSurveyResultRequest),
      switchMap((action) =>
        this.surveyClientApiService
          .surveyControllerGetSurveyResults(action.surveyId)
          .pipe(
            switchMap((response) => {
              return [
                getSurveyResultRequestSuccess({ surveyResults: response.data }),
                completeActionTracker({ action, data: response }),
              ];
            }),
            catchError((error) => {
              return of(
                failActionTracker({
                  action,
                  data: { error, message: error?.error?.message },
                }),
              );
            }),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private surveyClientApiService: SurveyClientApiService,
    private publicSurveyService: PublicSurveyClientApiService,
  ) {}
}
